import { ActionTypes, Machine, interpret } from 'xstate';
import { computed, reactive, ref, watch } from '@vue/composition-api';
import { EventData } from 'xstate/lib/types';
import {
  clearStateFromLocalStorage,
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from '@/lib/storage/local-storage';
import { NEXT } from '@/consts/assistant-state-events';
import { buildStates } from '@/lib/xstate/builder';
import { getInitialStep, getStep, getStepIndex } from '@/lib/steps/helper';
import { createContextFromSteps } from '@/lib/context';
import { StepDefinition } from '@/interfaces/step-definition';
import { AssistantContext } from '~/steps/steps';
import { AssistantMachine } from '@/interfaces/assistant';

// use local storage if env variable is set to "true"
const useLocalStorage = process.env.VUE_APP_USE_LOCALSTORAGE == 'true';

export const getFilesFromContext = (context: AssistantContext): File[] => {
  let files: File[] = [];
  Object.values(context).forEach((element) => {
    if (Array.isArray(element)) {
      element.forEach((fileOrSubcontext) => {
        if (fileOrSubcontext instanceof File) {
          files.push(fileOrSubcontext);
        } else {
          files = files.concat(getFilesFromContext(fileOrSubcontext));
        }
      });
    } else if (typeof element === 'object' && element !== null) {
      files = files.concat(getFilesFromContext(element));
    }
  });

  return files;
};

export const useAssistantMachine = (
  steps: StepDefinition[],
): AssistantMachine => {
  const context = reactive(createContextFromSteps(steps)) as AssistantContext;

  const assistantMachine = Machine({
    id: 'assistant',
    initial: getInitialStep(steps),
    states: buildStates(steps, context),
  });

  const service = interpret(assistantMachine).start();
  const currentState = ref(service.state.value as string);

  const currentStepIndex = computed(
    () => getStepIndex(currentState.value, steps) + 1 || 1,
  );

  const currentStep = computed(() => getStep(currentState.value, steps));

  const final = ref(false);

  const send = (transition: string, payload?: EventData | undefined): void => {
    service.send(transition, payload);
  };

  // set current state variable on transition and save state to local storage
  service.onTransition(async (newState, event) => {
    currentState.value = newState.value as string;

    if (
      useLocalStorage &&
      event.type !== ActionTypes.Init &&
      currentStep.value
    ) {
      saveStateToLocalStorage(context, currentStep.value.id);
    }
  });

  // watch context and save state every time it changes
  watch(context, (value: AssistantContext) => {
    if (useLocalStorage) {
      saveStateToLocalStorage(value, currentStep.value?.id || '');
    }
  });

  service.onDone(() => {
    final.value = true;

    if (useLocalStorage) {
      setTimeout(clearStateFromLocalStorage, 100);
    }
  });

  const setContext = (newContext: AssistantContext): void => {
    Object.entries(newContext).forEach(([key, value]) => {
      if (Object.keys(context).includes(key)) {
        context[key as string] = value;
      }
    });
  };

  const hasPersistedState = useLocalStorage
    ? ref(loadStateFromLocalStorage() !== null)
    : ref(false);

  const restoreState = (): void => {
    if (useLocalStorage) {
      const persistedState = loadStateFromLocalStorage();
      if (persistedState?.context) {
        setContext(persistedState.context);

        let i = 0;
        const stepId = persistedState.step || '';
        let reachedState = currentState.value === stepId;
        while (!reachedState && i < steps.length) {
          const nextState = service.send(NEXT);

          if (!nextState) {
            break;
          }

          if (nextState.value === stepId) {
            reachedState = true;
          }

          i++;
        }
      }
    }
  };

  return {
    context,
    currentState,
    currentStep,
    currentStepIndex,
    final,
    hasPersistedState,
    restoreState,
    send,
  };
};
