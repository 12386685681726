



















































import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import SkipMessage from '@/components/SkipMessage.vue';
import { JUMP_TO_CONTACTFORM, PREV } from '@/consts/assistant-state-events';
import GcProgressCircular from '@/components/primitives/GcProgressCircular.vue';
import AssistantStepsOverview from '@/components/AssistantStepsOverview.vue';
import AssistantHeader from '@/components/AssistantHeader.vue';
import StartScreen from '@/components/StartScreen.vue';
import { getContactFormStep, getStepIndex } from '@/lib/steps/helper';
import { StepDefinition } from '@/interfaces/step-definition';
import { AssistantMachine } from '@/interfaces/assistant';
import RestoreAlert from '@/components/RestoreAlert.vue';
import DefaultDone from '@/components/DefaultDone.vue';
import DefaultStep from '@/components/DefaultStep.vue';
import DefaultSummary from '@/components/DefaultSummary.vue';
import { StepTypes } from '@/consts/step-types';

export default defineComponent({
  components: {
    AssistantHeader,
    AssistantStepsOverview,
    GcProgressCircular,
    RestoreAlert,
    SkipMessage,
    StartScreen,
  },
  props: {
    machine: {
      required: true,
      type: Object as PropType<AssistantMachine>,
    },
    steps: {
      required: true,
      type: Array as PropType<StepDefinition[]>,
    },
  },
  setup: (props) => {
    const { final, send, currentStepIndex } = props.machine;
    const assistantStarted = ref(process.env.VUE_APP_SHOW_START === 'false');
    const stepTransition = ref('slide-fade');

    const showHeader = computed<boolean>((): boolean => {
      if (!process.env.VUE_APP_SHOW_HEADER) {
        return true;
      }

      if (process.env.VUE_APP_SHOW_HEADER === 'hide') {
        return false;
      }

      if (process.env.VUE_APP_SHOW_HEADER === 'start') {
        return !assistantStarted.value;
      }

      return true;
    });

    const jumpToContactForm = (): void => {
      send(JUMP_TO_CONTACTFORM);
    };

    const showSkipMessage = computed(() => {
      const contactFormStep = getContactFormStep(props.steps);
      if (!contactFormStep) {
        return false;
      }

      // Don't show skip message if step definition prevents it
      const hideMessageFunction =
        props.steps[currentStepIndex.value - 1].hideSkipMessage;
      if (
        typeof hideMessageFunction === 'function' &&
        hideMessageFunction(props?.machine?.context)
      ) {
        return false;
      }

      const contactFormStepIndex = getStepIndex(contactFormStep, props.steps);
      return currentStepIndex.value - 1 < contactFormStepIndex;
    });

    const onTransition = (transition: {
      event: string;
      payload?: any; // eslint-disable-line
    }): void => {
      stepTransition.value =
        transition.event === PREV ? 'slide-fade-reverse' : 'slide-fade';
      send(transition.event, transition.payload);
    };

    const overviewSteps = props.steps.map((step) => step?.id);

    const dynamicComponent = () => {
      if (props?.machine?.currentStep?.value?.component) {
        return props?.machine?.currentStep?.value.component;
      }

      if (props?.machine?.currentStep?.value?.type === StepTypes.SUMMARY) {
        return DefaultSummary;
      }

      if (props?.machine?.currentStep?.value?.type === StepTypes.FINAL) {
        return DefaultDone;
      }

      return DefaultStep;
    };

    return {
      DefaultDone,
      DefaultStep,
      StepTypes,
      assistantStarted,
      currentStepIndex,
      dynamicComponent,
      final,
      jumpToContactForm,
      onTransition,
      overviewSteps,
      showHeader,
      showSkipMessage,
      stepTransition,
    };
  },
});
