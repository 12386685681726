













































































import { Ref, defineComponent, ref } from '@vue/composition-api';
import {
  fetchProductData,
  getProdIdFromURL,
  openProdSite,
  removePrefix,
} from './service';
import Cell from './Cell.vue';
import { stepComponentProps } from '@/lib/steps/helper';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';

import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcSelect from '@/components/primitives/GcSelect.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import GcProgressCircular from '@/components/primitives/GcProgressCircular.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';

const comparedProdNum = 2;
const colWidth = 12 / (comparedProdNum + 1);

const displayedProps = [
  'price',
  'manufacturerIdentifier',
  'chargingPowersWatt',
  'chargingPowerConfigurable',
  'electricCurrentsAmpere',
  'electricCurrentConfigurable',
  'chargingConnectorTypes',
  'cableLengthMeter',
  'accessProtectionOptions',
  'pvCombinable',
  'energyMeters',
  'noOfChargingPoints',
  'loadManagement',
  'billingSolution',
  'networkConnectors',
  'protocols',
  'smartphoneControllable',
  'noOfPhases',
  'voltagesVolt',
  'protectionClasses',
  'outdoorReady',
  'climateNeutral',
];

interface SelectItem {
  text: string;
  value: string;
}

interface ProductItem {
  [key: string]: any; // eslint-disable-line
}

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    Cell,
    GcAlert,
    GcButton,
    GcCol,
    GcProgressCircular,
    GcRow,
    GcSelect,
  },
  props: {
    ...stepComponentProps,
  },
  setup: () => {
    let fetchedProdData;
    const prodNames = ref<SelectItem[]>([]);
    const prodDataList: Array<Ref<ProductItem | null>> = [];
    const firstSelectRef = ref<{
      selectItem: (item: ProductItem) => void;
    } | null>(null);
    const error = ref<boolean>(false);

    for (let i = 0; i < comparedProdNum; i++) {
      prodDataList.push(ref<ProductItem | null>(null));
    }

    const prodTitels: Array<Ref<string>> = [];
    for (let i = 0; i < comparedProdNum; i++) {
      prodTitels.push(ref<string>(''));
    }

    const updateProdDetails = (id, idx) => {
      prodDataList[idx].value = fetchedProdData[id]?.productWallboxData[0];
      prodTitels[idx].value =
        prodNames.value.find(({ value }) => value == id)?.text || '';
    };

    const order = (idx) => {
      const id = prodDataList[idx].value?.productId;
      const { seoPathInfo } = fetchedProdData[id];
      openProdSite(seoPathInfo);
    };

    const isLoading = ref(false);

    (async () => {
      try {
        isLoading.value = true;
        const items = await fetchProductData();
        prodNames.value = items.map(({ id, translated }) => {
          const name = translated && translated.name ? translated.name : '';
          const productId = id || '';

          return {
            text: removePrefix(name),
            value: productId,
          };
        });
        fetchedProdData = items.reduce(
          (acc, item) =>
            !item.id
              ? acc
              : {
                  ...acc,
                  [item.id]: {
                    ...item,
                  },
                },
          {},
        );

        setTimeout(() => {
          const prodId = getProdIdFromURL();
          const selectItem = prodNames.value.find(
            ({ value }) => value == prodId,
          );
          if (selectItem) {
            firstSelectRef.value?.[0].selectItem(selectItem);
          }
        });
      } catch {
        error.value = true;
      } finally {
        isLoading.value = false;
      }
    })();

    return {
      colWidth,
      comparedProdNum,
      displayedProps,
      error,
      firstSelectRef,
      isLoading,
      order,
      prodDataList,
      prodNames,
      prodTitels,
      updateProdDetails,
    };
  },
});
