import { SearchFilterType } from '@shopware-pwa/commons';
import prodOptsTranslates from './options';
import { getQueryParamByName } from '@/lib/url/query-params';
import { formatPrice } from '@/lib/formatter/price';
import { loadProductsByFilter } from '@/lib/greenportal/load-products';

export const fetchProductData = async () => {
  const res = await loadProductsByFilter({
    associations: {
      seoUrls: {},
      tags: {},
    },
    filter: [
      {
        operator: 'AND',
        queries: [
          {
            field: 'parentId',
            type: SearchFilterType.EQUALS,
            value: null,
          },
          {
            field: 'tags.name',
            type: SearchFilterType.EQUALS,
            value: 'wallbox',
          },
        ],
        type: SearchFilterType.MULTI,
      },
    ],
  });

  const mappedData = res
    .filter((item) => item.extensions.gcProductWallboxData?.length)
    .map((item) => {
      const {
        calculatedPrice,
        customFields,
        extensions,
        id,
        name,
        productNumber,
        seoUrls,
        translated,
      } = item;

      if (seoUrls === null || extensions.gcProductWallboxData === undefined) {
        return {};
      }

      const { seoPathInfo, pathInfo } = seoUrls[0];
      return {
        customFields,
        id,
        name,
        pathInfo,
        productNumber,
        productWallboxData: [
          {
            ...extensions.gcProductWallboxData[0],
            price: formatPrice(calculatedPrice.unitPrice),
          },
        ],
        seoPathInfo,
        translated,
      };
    });

  return mappedData;
};

export const openProdSite = (prodPath: string) => {
  window.open(`${process.env.VUE_APP_SHOP_URL}/${prodPath}`);
};

export const translateProdOption = (prod: string, name: string): string =>
  prodOptsTranslates[prod]?.options?.[name] || name;

export const removePrefix = (name: string): string => {
  const prefix = 'Wallbox ';
  if (name.indexOf(prefix) == 0) {
    return name.slice(prefix.length);
  }
  return name;
};

export const getProdIdFromURL = () => {
  const paramName = 'wallbox';
  return getQueryParamByName(paramName);
};
