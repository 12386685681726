/* eslint-disable @typescript-eslint/camelcase */
export default {
  accessProtectionOptions: {
    options: {
      app: 'App',
      key_switch: 'Schlüsselschalter',
      pin: 'PIN',
      rfid: 'RFID',
    },
  },

  chargingConnectorTypes: {
    options: {
      type_2_cable: 'Typ 2 Ladekabel',
      type_2_socket: 'Typ 2 Steckdose',
    },
  },

  displayTypes: {
    label: 'Art der Statusanzeige',
    options: {
      led: 'LED',
    },
  },
  electricProtectionDevices: {
    options: {
      dc_protection: 'DC Schutz',
      fi_type_a_with_dc_protection: 'FI Typ A mit DC Schutz',
      fi_type_b: 'FI Typ B',
    },
  },
  energyMeters: {
    options: {
      calibrated: 'Eichrechtskonform',
      mid: 'MID',
      uncalibrated: 'Ungeeicht',
    },
  },
  installationOptions: {
    options: {
      pillar_mount: 'Standsäule',
      wall_mount: 'Wandmontage',
    },
  },

  networkConnectors: {
    options: {
      lan: 'LAN',
      sim: 'SIM',
      wlan: 'WLAN',
    },
  },
  noOfChargingPoints: {
    options: {
      option1: '1 Ladepunkt',
      option2: '2 Ladepunkte',
    },
  },
  noOfPhases: {
    options: {
      option1: '1-phasig',
      option2: '2-phasig',
      option3: '3-phasig',
    },
  },
  protocols: {
    options: {
      modbus_tcp: 'ModbusTCP',
      ocpp: 'OCPP',
      udp: 'UDP',
    },
  },
};
