export const getQueryParamByName = (
  name: string,
  url?: string,
): string | null => {
  if (!url) {
    url = typeof window !== 'undefined' ? window.location.href : '';
  }
  name = name.replace(/[[\]]/g, '\\BODY');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
