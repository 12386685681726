// Each step has its own identifier
export enum StepId {
  COMPARISON = 'comparison',
  SUMMARY = 'summary',
  DONE = 'done',
}

// You can optionally divide steps into groups,
// each one having its own identifier
export enum StepCategoryId {}

export enum StepGroupId {}
