



























import { computed, defineComponent } from '@vue/composition-api';
import { faCheck, faMinus } from '@fortawesome/pro-solid-svg-icons';
import { translateProdOption } from './service';

export default defineComponent({
  props: ['wallboxData', 'propName'],
  setup(props) {
    const data = computed(() => {
      return props.wallboxData && props.wallboxData[props.propName];
    });
    return {
      props,
      data,
      faCheck,
      faMinus,
      translateProdOption,
    };
  },
  methods: {
    checkProductOption(propName, item, data) {
      const isConfigurableChargingPowersWatt =
        propName === 'chargingPowersWatt' &&
        this.props.wallboxData &&
        this.props.wallboxData.chargingPowerConfigurable === true;

      const isConfigurableElectricCurrentsAmpere =
        propName === 'electricCurrentsAmpere' &&
        this.props.wallboxData &&
        this.props.wallboxData.electricCurrentConfigurable === true;

      if (
        isConfigurableChargingPowersWatt ||
        isConfigurableElectricCurrentsAmpere
      ) {
        return item === Math.max(...data);
      }

      return true;
    },
    showFormattedProductOptions(propName, item) {
      if (propName === 'chargingPowersWatt') {
        item = item / 1000 + ' kW';
        if (this.props.wallboxData.chargingPowerConfigurable === true) {
          item = 'bis zu ' + item;
        }
      }

      if (propName === 'electricCurrentsAmpere') {
        item = item + ' A';
        if (this.props.wallboxData.electricCurrentConfigurable === true) {
          item = 'bis zu ' + item;
        }
      }

      if (propName === 'protectionClasses' && item.includes('ip')) {
        item = item.toUpperCase();
      }

      return translateProdOption(propName, item);
    },
  },
});
