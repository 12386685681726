import comparison from '~/steps/comparison';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [comparison];

// Add the imported context interface for the steps
export interface AssistantContext {}

export default steps;
