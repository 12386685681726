import {
  AnyEventObject,
  StatesConfig,
  TransitionConfig,
  TransitionsConfig,
} from 'xstate';
import { AssistantContext } from '~/steps/steps';
import {
  JUMP_TO_CONTACTFORM,
  NEXT,
  PREV,
} from '@/consts/assistant-state-events';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';

export const buildStates = (
  steps: Array<StepDefinition>,
  context: AssistantContext, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StatesConfig<AssistantContext, any, AnyEventObject> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stateMap: StatesConfig<AssistantContext, any, AnyEventObject> = {};

  steps.forEach((step: StepDefinition, index) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let transitions: TransitionsConfig<AssistantContext, any> = {};

    if (
      index > 0 &&
      (index < steps.length - 1 || step.type !== StepTypes.FINAL)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const prevTransition: TransitionConfig<AssistantContext, any>[] = [];

      let prevIndex = index - 1;
      while (typeof steps[prevIndex] !== 'undefined') {
        const isDisabled = steps[prevIndex].isDisabled;
        if (!isDisabled) {
          prevTransition.push({ target: steps[prevIndex].id });
          break;
        }

        prevTransition.push({
          cond: () => !isDisabled(context),
          target: steps[prevIndex].id,
        });

        prevIndex--;
      }

      transitions = {
        ...transitions,
        [PREV]: prevTransition,
      };
    }
    if (index < steps.length - 1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nextTransition: TransitionConfig<AssistantContext, any>[] = [];

      let nextIndex = index + 1;
      while (typeof steps[nextIndex] !== 'undefined') {
        const isDisabled = steps[nextIndex].isDisabled;
        if (!isDisabled) {
          nextTransition.push({ target: steps[nextIndex].id });
          break;
        }

        nextTransition.push({
          cond: () => !isDisabled(context),
          target: steps[nextIndex].id,
        });

        nextIndex++;
      }

      transitions = {
        ...transitions,
        [NEXT]: nextTransition,
      };
    }

    const contactFormStepIndex = steps.findIndex(
      (s) => s.type === StepTypes.CONTACT_FORM,
    );

    if (contactFormStepIndex !== -1 && index < contactFormStepIndex) {
      const contactFormStep = steps[contactFormStepIndex];
      transitions = {
        ...transitions,
        [JUMP_TO_CONTACTFORM]: { target: contactFormStep.id },
      };
    }

    // enable go back to previous steps
    if (step.type !== StepTypes.FINAL) {
      steps
        .filter((s) => s !== step)
        .slice(0, index)
        .forEach((s) => {
          if (s) {
            transitions = {
              ...transitions,
              ['BACK_TO_' + s.id.toUpperCase()]: {
                target: s.id,
              },
            };
          }
        });
    }

    stateMap[step.id] = {
      on: { ...transitions, ...step.transitions },
    };

    if (step.type === StepTypes.FINAL) {
      stateMap[step.id].type = 'final';
    }
  });
  return stateMap;
};
