
































































































import { defineComponent, ref } from '@vue/composition-api';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { OutputFormatterTypes } from '@/consts/output-formatter-types';
import { getFormattedData } from '@/lib/formatter/context';
import GcDivider from '@/components/primitives/GcDivider.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcExpansionPanel from '@/components/primitives/GcExpansionPanel.vue';
import GcExpansionPanelContent from '@/components/primitives/GcExpansionPanelContent.vue';
import GcExpansionPanelHeader from '@/components/primitives/GcExpansionPanelHeader.vue';
import GcExpansionPanels from '@/components/primitives/GcExpansionPanels.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcButton,
    GcCol,
    GcDivider,
    GcExpansionPanel,
    GcExpansionPanelContent,
    GcExpansionPanelHeader,
    GcExpansionPanels,
    GcForm,
    GcRow,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext, onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );

    const panels = ref([0]);

    const currentStepIndex =
      props.step && props.steps && props.steps.indexOf(props.step);
    const showBackButton = () =>
      props.step && props.steps && props.steps.indexOf(props.step) > 0;

    const summary = getFormattedData({
      assistantContext: props.assistantContext,
      currentStepIndex,
      steps: props.steps ?? [],
      target: OutputFormatterTypes.SUMMARY,
    });

    const editStep = (stepId: string) => {
      emit('transition', {
        event: `BACK_TO_${stepId.toUpperCase()}`,
      });
    };

    return {
      editStep,
      faPen,
      onNext,
      onPrev,
      panels,
      showBackButton,
      summary,
    };
  },
});
