/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  comparison: {
    title: 'Wallbox-Vergleich',
    step: {
      label: 'Wallbox-Vergleich',
    },
    prop: {
      price: 'Preis',
      manufacturerIdentifier: 'Hersteller-Nummer/Bezeichner',
      chargingPowersWatt: 'Ladeleistungen (kW)',
      chargingPowerConfigurable: 'Ladeleistung konfigurierbar',
      electricCurrentsAmpere: 'Stromstärken (A)',
      electricCurrentConfigurable: 'Stromstärke konfigurierbar',
      chargingConnectorTypes: 'Ladeanschlüsse',
      cableLengthMeter: 'Kabellänge (m)',
      accessProtectionOptions: 'Zugangsschutz',
      pvCombinable: 'Mit PV kombinierbar',
      energyMeters: 'Energiezähler',
      noOfChargingPoints: 'Anzahl der Ladepunkte',
      loadManagement: 'Lastmanagement',
      billingSolution: 'Abrechnungslösung',
      networkConnectors: 'Schnittstellen',
      protocols: 'Kommunikation',
      smartphoneControllable: 'Über Smartphone steuerbar',
      noOfPhases: 'Phasen',
      voltagesVolt: 'Spannungen (V)',
      protectionClasses: 'Schutzklassen',
      outdoorReady: 'Geeignet für Außenbereich',
      climateNeutral: 'Klimaneutral',
    },
    toTheProduct: 'Zum Produkt',
    toShoppingCart: 'In den Warenkorb',
  },
  start: {
    buttonText: 'Assistenten starten',
    description: '',
    title: 'Wallbox-Vergleichsassistent',
    step: {
      label: 'Start',
    },
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
