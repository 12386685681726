import component from './Comparison.vue';
import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';

const config: StepDefinition = {
  component,
  fields: [],
  id: StepId.COMPARISON,
};

export default config;
